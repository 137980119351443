import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap
import '../styles/about.css'; // Your custom CSS (if needed)

const About = () => {
  return (
    <div className="container my-5 pt-5">
      <h2 className="text-center mb-4 about-title">About Me</h2>
      <div className="row">
        {/* Education Section */}
        <div className="col-md-6">
          <h3 className="education-title">Education</h3>
          <div className="education-item mb-3">
            <h4>Bachelor of Engineering (BE) in Computer Engineering</h4>
            <p className="year">2023</p>
            <p>Savitribai Phule Pune University, Pune</p>
          </div>
          <div className="education-item mb-3">
            <h4>Diploma in Computer Technology</h4>
            <p className="year">2020</p>
            <p>The Maharashtra State Board of Technical Education (MSBTE), Mumbai</p>
          </div>
          <div className="education-item mb-3">
            <h4>Bachelor of Science (B.Sc) in Industrial Science</h4>
            <p className="year">2020</p>
            <p>Yashwantrao Chavan Maharashtra Open University, Nashik, Maharashtra</p>
          </div>
        </div>
        {/* About Paragraph Section */}
        <div className="col-md-6">
        <h3 className="education-title">Summary....</h3>
          <p className="about-paragraph">
            I am a passionate software developer specializing in full-stack development. With expertise in React, Node.js, and Python, I enjoy creating dynamic web applications that solve real-world problems. My journey is driven by curiosity, and I strive to push the boundaries of technology while exploring machine learning and generative AI.
          </p>
          <p className="about-paragraph">
            As an active contributor to the tech community, I believe in the power of collaboration and sharing knowledge. I regularly engage in discussions on software development and AI advancements, aiming to inspire others through blogging and workshops.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
