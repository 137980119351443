import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/projects.css'; // Import custom CSS if needed

const projects = [ 
  {
    title: "Karnala Charitable Trust Website",
    techStack: "React.js, Node.js, MySQL",
    description: "Built a responsive website featuring event management, publications, blog, and donation system. Created a dynamic dashboard for admin control over content, blogs, and donations.",
    link: "https://rootneurons.netlify.app/"
  },
  {
    title: "Purbicon Animals Health Pvt. Ltd. Website",
    techStack: "HTML, Bootstrap, PHP",
    description: "Designed and developed the official website for Purbicon Animals Health Pvt. Ltd. Showcased the company’s mission, cutting-edge solutions, and commitment to innovative research and informative content.",
    link: "https://purbicon.com/"
  },
  {
    title: "The Dark Room",
    techStack: "React.js, Node.js, WebSocket",
    description: "A real-time chat application using React.js for the front end and Node.js with Express.js for the back end. Integrated WebSocket for live communication between users, styled with a modern, user-friendly dark theme.",
    link: "https://example.com/thedarkroom" // Replace with actual link
  },
  {
    "title": "Healthcare Chatbot",
    "techStack": "Python, TensorFlow, NLP",
    "description": "Built a healthcare chatbot using TensorFlow and Natural Language Processing techniques. The chatbot provides medical advice, symptom checking, and appointment scheduling.",
    "link": "https://example.com/healthcarechatbot" // Replace with actual link
  },
  {
    title: "Image Classifier",
    techStack: "Python, TensorFlow, Keras",
    description: "Developed an image classification application using TensorFlow and Keras. The model can identify objects in images with high accuracy, demonstrating deep learning capabilities through a user-friendly interface.",
    link: "https://example.com/imageclassifier" // Replace with actual link
  },
  {
    title: "Sentiment Analysis Tool",
    techStack: "Python, NLTK, Flask",
    description: "Created a sentiment analysis tool using NLTK for natural language processing. The web application allows users to input text and provides insights on sentiment polarity and subjectivity in real-time.",
    link: "https://example.com/sentimentanalysis" // Replace with actual link
  },
  {
    title: "IMDB Movie Recommendation System",
    techStack: "Python, Flask, Scikit-learn",
    description: "Built a movie recommendation system using Scikit-learn and Flask. The application analyzes user preferences to suggest movies based on their viewing history, utilizing collaborative filtering techniques.",
    link: "https://example.com/imdbrecommendation" // Replace with actual link
  }
];

const Projects = () => {
  // State to track button text for each project
  const [buttonText, setButtonText] = useState(Array(projects.length).fill("View Project"));

  // Function to handle project link click
  const handleProjectClick = (index) => {
    if (index > 1) {
      setButtonText((prev) => {
        const newText = [...prev];
        newText[index] = "For more details reach out to the author";
        return newText;
      });
    } else {
      window.open(projects[index].link, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <div className="container my-5">
      <h2 className="text-center mb-4 projects-title">My Projects</h2>

      <div className="row">
        {projects.map((project, index) => (
          <div className="col-md-6 mb-4" key={index}>
            <div className="card shadow-sm">
              <div className="card-body">
                <h5 className="card-title">{project.title}</h5>
                <h6 className="card-subtitle mb-2 text-muted">{project.techStack}</h6>
                <p className="card-text">{project.description}</p>
                <button
                  className="btn btn-primary"
                  onClick={() => handleProjectClick(index)}
                >
                  {buttonText[index]}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
