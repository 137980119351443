import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/navbar.css'; // Ensure you have your CSS in this file
import logo from '../images/logo.gif'; // Adjust the path to your logo image

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false); // Local state to toggle the menu

  const toggleMenu = () => {
    setIsOpen((prev) => !prev); // Toggle the local menu state
  };

  // Function to close the menu when a link is clicked
  const closeMenu = () => {
    setIsOpen(false); // Set isOpen to false to close the menu
  };

  return (
    <header className="navbar">
      <div className="navbar__logo">
        <Link to="/">
          <img src={logo} alt="Logo" className="logo" /> {/* Logo Image */}
        </Link>
      </div>
      <div className="navbar-title-container">
        <h1 className="navbar-title show-first">सचिन | Sachin</h1>
      </div>

      <nav className={`navbar__menu ${isOpen ? 'active' : ''}`}>
        <ul className="navbar__links">
          <li onClick={closeMenu}><Link to="/">Home</Link></li>
          <li onClick={closeMenu}><Link to="/about">About</Link></li>
          <li onClick={closeMenu}><Link to="/projects">Projects</Link></li>
          <li onClick={closeMenu}><Link to="/skills">Skills</Link></li>
          <li onClick={closeMenu}><Link to="/resume">Resume</Link></li>
          <li onClick={closeMenu}><Link to="/contact">Contact</Link></li>
          <li onClick={closeMenu}><Link to="/blog">Blog</Link></li>
        </ul>
      </nav>
      <div className={`navbar__toggle ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </header>
  );
};

export default Navbar;
