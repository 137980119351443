import React from 'react';
import About from './About';
import Projects from './Projects';
import Skills from './Skills';
import Resume from './Resume';
import Contact from './Contact';
import Blog from './Blog';

import '../styles/home.css'; // Link to your custom CSS

const Home = () => {
  return (
    <div className="home">
      {/* Hero Section with Animated Background */}
      <section className="hero">
        <div className="hero-content">
        <h1>Welcome to My Journey</h1>
        <p>Hi, I'm Sachin, an engineer with a curious mind.</p>
        </div>

        {/* Quotes Section */}
        <div className="quotes">
          <blockquote>
            <p>"I could either watch it happen or be a part of it." – Elon Musk</p>
          </blockquote>
        </div>
      </section>

            {/* About Section */}
            <About />

{/* Projects Section */}
<Projects />

{/* Skills Section */}
<Skills />

{/* Resume Section */}
<Resume />

{/* Contact Section */}
<Contact />

{/* Blog Section */}
<Blog />
    </div>
  );
};

export default Home;
