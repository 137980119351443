import React, { useState } from 'react';
import '../styles/contact.css'; // Import your CSS for styling

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [loading, setLoading] = useState(false); // New loading state

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when starting the request

    try {
      // Send request to the backend
      const response = await fetch('http://localhost:5000/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, message }),
      });

      // Parse the JSON response
      const data = await response.json();

      // Handle successful response
      if (response.ok) {
        setResponseMessage("We have successfully received your message! We'll be in touch with you shortly");
        // Clear the form after success
        setName('');
        setEmail('');
        setMessage('');
      } else {
        // Handle error response
        setResponseMessage(data.status || 'Error sending message, please try again later.');
      }
    } catch (error) {
      // Log detailed error to the console
      console.error('Error details:', error);
      setResponseMessage('Error sending message, please try again later.');
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <div className="contact-container">
      <div className="map-container">
        {/* Google Maps embed or any other map here */}
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d389148.2962785565!2d73.72442423607923!3d18.520430208891468!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b7c4a0dc05ef%3A0x71a6d50eb6c06c0c!2sPune%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1640768942675!5m2!1sen!2sin"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          title="Pune Location Map"
        ></iframe>
      </div>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>
            <span>Name:</span>
            <input 
              type="text" 
              value={name} 
              onChange={(e) => setName(e.target.value)} 
              required 
              className="form-control"
            />
          </label>
        </div>
        <div className="form-group">
          <label>
            <span>Email:</span>
            <input 
              type="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
              className="form-control"
            />
          </label>
        </div>
        <div className="form-group">
          <label>
            <span>Message:</span>
            <textarea 
              value={message} 
              onChange={(e) => setMessage(e.target.value)} 
              required 
              className="form-control"
            />
          </label>
        </div>
        <button type="submit" className="submit-button" disabled={loading}> {/* Disable button while loading */}
          {loading ? 'Sending...' : 'Send Message'}
        </button>
      </form>
      {responseMessage && (
        <div className={`response-message ${responseMessage.includes('success') ? 'success' : 'error'}`}>
          <p>{responseMessage}</p>
          <button className="close-btn" onClick={() => setResponseMessage('')}>&times;</button>
        </div>
      )}
      <div className="contact-details">
        <h3>Get in Touch with Us!</h3>
        <p><strong>Contact No:</strong> +91 9763841037</p>
        <p><strong>Address:</strong> Pune, Maharashtra</p>
        <p><strong>Email:</strong> sachinsabale27@gmail.com</p>
      </div>
    </div>
  );
};

export default Contact;
