import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/footer.css'; // Your custom CSS (if needed)

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container d-flex justify-content-end align-items-center">
        <p className="mb-0 me-3">© {new Date().getFullYear()} Sachin Sabale. All rights reserved.</p>
        <div>
          <a 
            href="https://www.linkedin.com/in/sachin-sabale-ba5a25166/" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="footer-link"
          >
            LinkedIn
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
