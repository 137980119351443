import React from 'react';
import '../styles/resume.css';

const Resume = () => {
  return (
    <div className="container my-5">
      <h2 className="resume-title">Resume</h2>
      <p className="text-center">
        I'm excited to share my professional journey with you. My resume highlights my experience, skills, and projects
        that showcase my capabilities in software development and technology. You can download my resume using the link
        below:
      </p>
      <div className="text-center">
        <a href="/path/to/resume.pdf" download className="btn btn-primary btn-lg mt-3">
          Download Resume
        </a>
      </div>
    </div>
  );
};

export default Resume;
