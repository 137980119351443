import React from 'react';
import '../styles/blog.css'; // Importing the CSS file for styling

const Blog = () => {
  const blogs = [
    {
      title: 'आठवणी आणि उजाळा (Memories and Enlightenment)',
      author: 'Sachin Sabale',
      date: '6 months ago',
      content: (
        <span>
          धूळ माखलेली जुनी वस्तू हातात घेतली कि जुन्या आठवणी जागृत होतात ना?
          <br />
          माझ्या या मराठी लेखात(Article), मी जुन्या वस्तूंच्या आकर्षणाचा आणि त्यांच्याशी
          निगडित भावनिक आठवणींबद्दल लिहिण्याचा एक छोटासा प्रयत्न ..........
          <br />
          <a href="https://www.linkedin.com/pulse/%E0%A4%86%E0%A4%A0%E0%A4%B5%E0%A4%A3-%E0%A4%86%E0%A4%A3-%E0%A4%89%E0%A4%9C%E0%A4%B3memories-enlightenment-sachin-sabale-t0gic/?trackingId=sF9RDUGnTa6x1AJuZ48kQg%3D%3D" target="_blank" rel="noopener noreferrer">
            Read more
          </a>
        </span>
      ),
      hashtags: '#मराठीब्लॉग #मराठी #Marathi #Books #Engineering #ai #Technology',
      readTime: '2 min read'
    },
    {
      title: 'Evolution of Transformer Models in Natural Language Processing',
      author: 'Sachin Sabale',
      date: '5 months ago',
      content: (
        <span>
          📚 🎉 Exciting Announcement! My new LinkedIn article is live,
          and it's all about the game-changing potential of language models.
          <br />
          <a href="https://www.linkedin.com/feed/update/urn:li:activity:7184455001484746753/" target="_blank" rel="noopener noreferrer">
            Read more
          </a>
        </span>
      ),
      hashtags: '#NLP #TransformerModels #AI #MachineLearning #DeepLearning #DataScience #LanguageProcessing #Innovation #DigitalTransformation #ResearchPaper #Education #STEM #ArtificialIntelligence #innomatics',
      readTime: '5+ min read'
    }
  ];

  return (
    <div className="blog-container">
      <h1 className="blog-title">My Blog</h1>
      {blogs.map((blog, index) => (
        <div className="blog-card" key={index}>
          <h2 className="blog-card-title">{blog.title}</h2>
          <p className="blog-card-author">{blog.author} • {blog.date} • {blog.readTime}</p>
          <p className="blog-card-content">{blog.content}</p>
          <p className="blog-card-hashtags">{blog.hashtags}</p>
        </div>
      ))}
    </div>
  );
};

export default Blog;
