import React from 'react';
import '../styles/skills.css';

const Skills = () => {
  return (
    <div className="container my-5">
      <h2 className="skills-title">My Skills</h2>
      <div className="row">
        {/* Card for Web and Application Development */}
        <div className="col-md-4">
          <div className="card skill-card">
            <div className="card-body">
              <h5 className="card-title">Web Development</h5>
              <ul className="list-unstyled">
                <li className="skill-item"><i className="fas fa-check-circle"></i> React.js</li>
                <li className="skill-item"><i className="fas fa-check-circle"></i> Node.js</li>
                <li className="skill-item"><i className="fas fa-check-circle"></i> HTML & CSS</li>
                <li className="skill-item"><i className="fas fa-check-circle"></i> Bootstrap</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Card for Machine Learning */}
        <div className="col-md-4">
          <div className="card skill-card">
            <div className="card-body">
              <h5 className="card-title">Machine Learning</h5>
              <ul className="list-unstyled">
                <li className="skill-item"><i className="fas fa-check-circle"></i> Python</li>
                <li className="skill-item"><i className="fas fa-check-circle"></i> TensorFlow</li>
                <li className="skill-item"><i className="fas fa-check-circle"></i> Scikit-learn</li>
                <li className="skill-item"><i className="fas fa-check-circle"></i> Data Analysis</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Card for Databases */}
        <div className="col-md-4">
          <div className="card skill-card">
            <div className="card-body">
              <h5 className="card-title">Databases</h5>
              <ul className="list-unstyled">
                <li className="skill-item"><i className="fas fa-check-circle"></i> MySQL</li>
                <li className="skill-item"><i className="fas fa-check-circle"></i> MongoDB</li>
                <li className="skill-item"><i className="fas fa-check-circle"></i> PostgreSQL</li>
                <li className="skill-item"><i className="fas fa-check-circle"></i> Firebase</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Card for Generative AI */}
        <div className="col-md-4">
          <div className="card skill-card">
            <div className="card-body">
              <h5 className="card-title">Generative AI</h5>
              <ul className="list-unstyled">
                <li className="skill-item"><i className="fas fa-check-circle"></i> NLP</li>
                <li className="skill-item"><i className="fas fa-check-circle"></i> GANs</li>
                <li className="skill-item"><i className="fas fa-check-circle"></i> Image Synthesis</li>
                <li className="skill-item"><i className="fas fa-check-circle"></i> Text Generation</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Card for Computer Vision */}
        <div className="col-md-4">
          <div className="card skill-card">
            <div className="card-body">
              <h5 className="card-title">Computer Vision</h5>
              <ul className="list-unstyled">
                <li className="skill-item"><i className="fas fa-check-circle"></i> OpenCV</li>
                <li className="skill-item"><i className="fas fa-check-circle"></i> Image Processing</li>
                <li className="skill-item"><i className="fas fa-check-circle"></i> Object Detection</li>
                <li className="skill-item"><i className="fas fa-check-circle"></i> Facial Recognition</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
